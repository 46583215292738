
.p-topics {
  &-list {
    display: flex;
    margin: 0 -30px;

    flex-wrap: wrap;

    &-item {
      width: 33.3333%;
      padding: 30px;

      @include view-at(sp) {
        width: 100%;
      }
    }
  }

  &-title {
    font-size: 18px;
    line-height: 1.5;
    margin: 20px 0;
    color: $color-red;
    font-weight: 400;

    + p {
      overflow: hidden;
      position: relative;
      max-height: 66px;
      text-align: justify;
      color: $color-black;

      &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #fff;
        width: 1em;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 22px;
        background: white;
      }
    }
  }
}
