
.wp-pagenavi{
  text-align: center;
  margin-top: 50px;
  .pages{
    display: none;
  }
  span, a{
    /*数字部分の共通CSS　大きさなど*/
    font-size: 14px;
    letter-spacing: 0.2em;
    line-height: 19px;
    display: inline-block;
    margin: 0 7.5px;
    padding: 5px 8px;
    text-decoration: none;
    color: $color-black;

    &.current, &:hover{
      /*現在のページ*/
      color: $color-white;
      background: $color-red;
    }
  }
}
