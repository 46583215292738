.c-table {
  border-top: 1px solid rgba(0,0,0,0.3);
  width: 100%;
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  tbody {
    tr {
      border-bottom: 1px solid rgba(0,0,0,0.3);
    }
    th {
      padding: 15px;
      line-height: 1.5;
      font-weight: normal;
      width: 150px;
      font-weight: 400;
      text-align: right;
    }
    td {
      padding: 15px;
      line-height: 1.5;
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: 400;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }
}
