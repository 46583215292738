
.u-border {
  &-bottom {
    border-bottom: 1px solid $color-red;
  }

  &-top {
    border-top: 1px solid $color-red;
  }

  &--white {
    border-color: $color-white;
  }
}
