.u-text-accent {
  color: $color-red;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-bold {
  font-weight: 400;
}

.u-text-normal {
  font-weight: 300;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-text-x-large {
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  letter-spacing: 0.06em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
  }
}

.u-text-large {
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.03em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
  }
}

.u-text-bit-large {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);
  letter-spacing: 0.03em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.u-text-medium {
  font-size: nth($font-size, 4) !important;
  line-height: nth($line-height, 4);
  letter-spacing: 0.03em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.u-text-default {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.u-text-small {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.u-text-x-small {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}
