/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */
.c-menu {
  display: none;

  @include view-at(sp) {
      position: absolute;
      right: 15px;
      top: 17px;
      display: block;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all .2s;
    box-sizing: border-box;
  }

  &-trigger {
    position: relative;
    width: 22px;
    height: 16px;
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-black;
  }

  &-trigger span:nth-of-type(1) {
    top: 0;
  }

  &-trigger span:nth-of-type(2) {
    top: 7px;
  }

  &-trigger span:nth-of-type(3) {
    bottom: 0;
  }

  &-trigger.active span:nth-of-type(1) {
    -webkit-transform: translateY(7px) rotate(-45deg);
    transform: translateY(7px) rotate(-45deg);
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    -webkit-transform: translateY(-7px) rotate(45deg);
    transform: translateY(-7px) rotate(45deg);
  }
}
