.c-label {
  display: inline-block;
  padding: 6px 8px;
  line-height: 1;

  &--primary {
    background-color: $color-blue;
    color: $color-white;
  }

  &--min {
    padding: 3px;
  }
}
