
.c-form {
  &-text {
    border: 1px solid #e9e9e9;
    width: 100%;
    padding: 8px 5px;
  }

  &-textarea {
    border: 1px solid #e9e9e9;
    width: 100%;
    padding: 8px 5px;
    height: 100px;
  }
}
