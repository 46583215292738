.c-breadcrumb {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li {
      &::after {
        content: '>';
        padding: 0 5px;
      }
      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}
