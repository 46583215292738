
body {
  font-family: 'Noto Sans JP', "游ゴシック体", YuGothic, "Yu Gothic M", "游ゴシック Medium", "Yu Gothic Medium", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: $color-black;
}

a {
  color: $color-black;

  &:hover {
    opacity: 0.6;
  }
}

p {
  text-align: justify;
  word-wrap: break-word;
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
    margin-bottom: 10px;
  }
}

img {
  // width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  margin-top: 130px;
  @include view-at(sp) {
    margin-left: 0;
    margin-top: 50px;
  }
}

.wp-pagenavi {
  text-align: center;
}

.wp-pagenavi .page {
  margin: 0 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  &-item {
    padding: 5px;
  }
}

.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.3333%;
}

.gallery-columns-4 .gallery-item {
  width: 25%;

  @include view-at(sp) {
    width: 50%;
  }
}

.gallery-columns-5 .gallery-item {
  width: 20%;

  @include view-at(sp) {
    width: 50%;
  }
}
