.p-header {
  padding: 25px 0 0;
  background-color: $color-gray-snow;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 2px rgba(0,0,0,0.4);

  @include view-at(sp) {
    height: 50px;
    padding: 10px 0 0;
  }

  &-logo {
    margin-bottom: 22px;
    text-align: center;
    font-size: 0;

    @include view-at(sp) {
      margin-bottom: 10px;
    }

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;

      @include view-at(pc) {
        img {
          width: auto;
          height: 50px;
        }
      }

      @include view-at(tab) {
        margin-left: 20px;
      }

      @include view-at(sp) {
        width: 110px;
        height: 30px;
      }
    }
  }
}
