.c-link {
  &--detail {
    color: $color-red;
  }

  &--block {
    display: block;
    text-decoration: none;
  }

  &--none {
    text-decoration: none;
  }
}
