
.p-exnav {
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  width: $contents-width;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 $contents-padding;
  font-size: 13px;

  @include view-at(sp) {
    position: static;
    padding: 0;
  }

  &-list {
    display: flex;
    justify-content: flex-end;

    @include view-at(sp) {
      display: block;
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
    }

    &-item {
      padding: 0 20px;

      @include view-at(sp) {
        padding: 0;
        width: 50%;
      }

      a {
        text-decoration: none;
        color: $color-gray-dark;

        @include view-at(sp) {
          padding: 20px;
          display: block;
          color: $color-white;
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &-sub {
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    width: $contents-width;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 $contents-padding;
    font-size: 13px;

    @include view-at(sp) {
      display: block;
      position: static;
      padding: 0;
    }

    &-list {
      display: flex;
      justify-content: flex-end;

      @include view-at(sp) {
        flex-wrap: wrap;
        justify-content: flex-start;
        text-align: center;
      }

      &-item {
        padding: 0 15px;

        @include view-at(sp) {
          padding: 0;
          width: 33.33333%;
        }

        a {
          text-decoration: none;

          @include view-at(sp) {
            padding: 20px;
            display: block;
            color: $color-white;
          }
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
