
.editor-writing-flow__click-redirect {
  margin-bottom: 0;
}

.editor-writing-flow,
.entry-content {
  img {
    max-width: 100%;
  }

  h2 {
    margin: 40px 0 20px;
    font-size: nth($font-size, 2);
    border-bottom: 1px solid $color-red;
    line-height: 1.3;
    padding-bottom: 5px;
    font-weight: 400;
  }

  h3 {
    margin: 40px 0 20px;
    font-size: nth($font-size, 4);
    line-height: 1.3;
    font-weight: 400;
  }

  &::after {
    content: "";
    clear: both;
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }

  ul {
    @extend .c-list--point;
  }

  ol {
    @extend .c-list--order;
  }

  table {
    @extend .c-table;
  }

  .wp-block-gallery {
    list-style: none;

    > li {
      &::before {
        display: none;
      }
    }
  }
}

.page-content,
.wp-block-freeform {
  h2 {
    @extend .c-h2;
    border-bottom: 0;
    padding-bottom: 0;
  }
  h3 {
    @extend .c-h3;
  }
  h4 {
    @extend .c-h4;
  }
}

.wp-block {
  max-width: 100%;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  display: block;
  margin: 0 0 0 auto;
}

.wp-block-gallery {
  list-style: none;

  > li {
    &::before {
      display: none;
    }
  }
}

.wp-block-media-text {
  align-items: inherit;
}

.wp-block-media-text .wp-block-media-text__content {
  padding: 0 8% 0 0;
}

.wp-block-table table{
  @extend .c-table;
}

.wp-block-columns {
  @include view-at(pc) {
    flex-wrap: nowrap;
  }

  @include view-at(sp) {
    flex-wrap: wrap;
  }
}

.wp-block-column {
  @include view-at(pc) {
    flex-basis: calc(50% - 16px);
  }

  @include view-at(sp) {
    flex-basis: 100%;
  }

  &:not(:first-child) {
    @include view-at(pc) {
      margin-left: 32px;
    }

    @include view-at(sp) {
      margin-left: 0;
    }
  }
}
