.c-button {
  display: inline-block;
  width: auto;
  border: 1px solid $color-red;
  padding: 15px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-white;
  background-color: $color-red;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &--primary {
    padding: 22px;
    font-size: nth($font-size, 2);
    min-width: 200px;
  }

  &--small {
    padding: 10px 15px;
  }
}
