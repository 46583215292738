
.p-footer {
  background-color: $color-gray-snow;
  padding: 80px 0 20px;
  color: $color-gray-dark;

  @include view-at(sp) {
    padding: 40px 0 20px;
  }

  &-logo {
    text-align: center;
    max-width: 200px;
    margin-bottom: 20px;
    margin-right: 60px;
    flex-basis: 200px;

    @include view-at(sp) {
      margin: 0 auto 20px;
    }
  }

  &-contents {
    display: flex;
    margin-bottom: 40px;

    @include view-at(sp) {
      display: block;
      font-size: 13px;
    }

    a {
      color: $color-gray-dark;
    }
  }

  &-copyright {
    display: flex;
    justify-content: space-between;

    @include view-at(sp) {
      flex-direction: column-reverse;
    }
  }

  &-link {
    @include view-at(sp) {
      margin-bottom: 20px;

      .c-list--horizontal {
        display: block;
        li {
          margin: 10px 0;
        }
      }
    }

    a {
      text-decoration: none;
      font-size: 13px;
      color: $color-gray-dark;
    }
  }
}
