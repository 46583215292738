
.u-align-left {
  text-align: left;
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

@include view-at(sp) {
  .u-align-left--sp {
    text-align: left;
  }

  .u-align-right--sp {
    text-align: right;
  }

  .u-align-center--sp {
    text-align: center;
  }
}
