
.p-gnav {
  border-top: 10px solid $color-red;
  background-color: $color-white;

  @include view-at(sp) {
    display: none;
    background-color: $color-gray;
  }

  &-list {
    display: flex;
    justify-content: center;

    @include view-at(sp) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
    }

    &-item {
      padding: 10px 25px;
      display: flex;
      justify-content: center;

      @include view-at(tab) {
        padding: 10px;
      }

      @include view-at(sp) {
        padding: 0;
        width: 50%;
      }

      // &.current {
      //   a {
      //     color: #fff;
      //     pointer-events: none;
      //   }
      // }
      //
      a {
        font-size: nth($font-size, 5);
        letter-spacing: 0.05em;
        color: $color-gray-dark;
        text-decoration: none;
        line-height: 1;
        font-weight: 400;

        @include view-at(sp) {
          padding: 20px;
          color: $color-white;
        }
      }
    }
  }
}
