
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=21750147-d652-4704-9a95-8b5f2d411f76");
    @font-face{
        font-family:"FrutigerNeueW01-Regular";
        src:url("../../fonts/e2e74e1c-9e18-4127-adfd-feb8f6a9ceaf.eot?#iefix");
        src:url("../../fonts/e2e74e1c-9e18-4127-adfd-feb8f6a9ceaf.eot?#iefix") format("eot"),url("../../fonts/9edf3443-80c6-4de6-b582-122401f63ad4.woff2") format("woff2"),url("../../fonts/aa94077e-cdcd-4e33-abe0-ddcfe27e3485.woff") format("woff"),url("../../fonts/22dfb43f-26df-46f9-aa0f-30038f8da834.ttf") format("truetype"),url("../../fonts/36ad2de1-95ea-4eb3-a836-29c86179a248.svg#36ad2de1-95ea-4eb3-a836-29c86179a248") format("svg");
    }
    @font-face{
        font-family:"Frutiger Neue W01 Md";
        src:url("../../fonts/979450a2-8d59-4cc2-9937-94255a4f3923.eot?#iefix");
        src:url("../../fonts/979450a2-8d59-4cc2-9937-94255a4f3923.eot?#iefix") format("eot"),url("../../fonts/1f198a6d-f4fe-44f1-b78c-1b6a1cf66d00.woff2") format("woff2"),url("../../fonts/4748e3d8-1b23-420a-a419-d7a367ea1076.woff") format("woff"),url("../../fonts/11f76f62-1d4f-4327-bce7-1eee2aba08bf.ttf") format("truetype"),url("../../fonts/ce4a6e96-f298-4ab1-a2ac-bdbbbc1467d6.svg#ce4a6e96-f298-4ab1-a2ac-bdbbbc1467d6") format("svg");
    }
    @font-face{
        font-family:"Frutiger Neue W01 Bd";
        src:url("../../fonts/62057895-1f9e-4d70-bd1a-65751a20a878.eot?#iefix");
        src:url("../../fonts/62057895-1f9e-4d70-bd1a-65751a20a878.eot?#iefix") format("eot"),url("../../fonts/26f1236c-a914-477f-9d0f-10d758702626.woff2") format("woff2"),url("../../fonts/7875f3d5-b62b-445c-8c9e-d65da1514e9d.woff") format("woff"),url("../../fonts/1c242f4e-0c64-47cc-8cbc-e53d949280ba.ttf") format("truetype"),url("../../fonts/ec132df9-012a-4594-bbd9-c86574bee7b0.svg#ec132df9-012a-4594-bbd9-c86574bee7b0") format("svg");
    }
