$padding: 30px;

.u-row {
  margin: 0 -30px;
  display: flex;
  flex-wrap: wrap;

  &-align-center {
    align-items: center;
  }

  &-reverse {
    flex-direction: row-reverse;
  }

  &--narrow {
    margin: -5px;
  }

  &-nomargin {
    margin: 0;
  }
}

@for $i from 1 through 12
{
  $width: (100% / (12/$i));
  .u-col-#{$i}
  {
      width: $width;
      padding: $padding;
  }

  .u-col-offset-0
  {
      margin-left: 0;
  }

  .u-col-offset-#{$i}
  {
      margin-left: $width;
  }

  .u-row--narrow {
    .u-col-#{$i}
    {
      padding: 5px;
    }
  }

  .u-row-nomargin {
    .u-col-#{$i}
    {
      padding: 0;
    }
  }
}

@include view-at(pc) {
  @for $i from 1 through 12
  {
      $width: (100% / (12/$i));
      .u-col-#{$i}--pc
      {
          width: $width;
          padding: $padding;
      }

      .u-col-offset-0--pc
      {
          margin-left: 0;
      }

      .u-col-offset-#{$i}--pc
      {
          margin-left: $width;
      }

      .u-row--narrow {
        .u-col-#{$i}--pc
        {
          padding: 5px;
        }
      }

      .u-row-nomargin {
        .u-col-#{$i}--pc
        {
          padding: 0;
        }
      }
  }
}

@include view-at(tab) {
  @for $i from 1 through 12
  {
      $width: (100% / (12/$i));
      .u-col-#{$i}--tab
      {
          width: $width;
          padding: $padding;
      }

      .u-col-offset-0--tab
      {
          margin-left: 0;
      }

      .u-col-offset-#{$i}--tab
      {
          margin-left: $width;
      }

      .u-row--narrow {
        .u-col-#{$i}--tab
        {
          padding: 5px;
        }
      }

      .u-row-nomargin {
        .u-col-#{$i}--tab
        {
          padding: 0;
        }
      }
  }
}

@include view-at(sp) {
    @for $i from 1 through 12
    {
        $width: (100% / (12/$i));
        .u-col-#{$i}--sp
        {
            width: $width;
            padding: $padding;
        }

        .u-col-offset-0--sp
        {
            margin-left: 0;
        }

        .u-col-offset-#{$i}--sp
        {
            margin-left: $width;
        }

        .u-row--narrow {
          .u-col-#{$i}--sp
          {
            padding: 5px;
          }
        }

        .u-row-nomargin {
          .u-col-#{$i}--sp
          {
            padding: 0;
          }
        }
    }
}
