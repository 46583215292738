


.c-h1 {
  margin-top: 190px;
  color: $color-black;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
    padding: 15px;
    margin-top: 110px;
  }

  small {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}

.c-h2 {
  color: $color-red;
  text-align: center;
  margin: 40px auto;
  font-weight: 400;
  font-size: nth($font-size, 1);
  line-height: 1.3;
  letter-spacing: 0.15em;
  text-indent: 0.2em;

  @include view-at(sp) {
    margin: 40px auto;
  }

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 1px;
    background-color: $color-red;
    margin: 15px auto 0;

  }
}

.c-h3 {
  color: $color-red;
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 15px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
  }
}

.c-h4 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: 400;
  margin-bottom: 15px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h1-mb {
  @include view-at(pc) {
    margin-bottom: nth($space, 2);
  }

  @include view-at(tab) {
    margin-bottom: nth($space-tab, 2);
  }

  @include view-at(sp) {
    margin-bottom: nth($space-sp, 2);
  }
}

.c-h2-mb {
  @include view-at(pc) {
    margin-bottom: nth($space, 3);
  }

  @include view-at(tab) {
    margin-bottom: nth($space-tab, 3);
  }

  @include view-at(sp) {
    margin-bottom: nth($space-sp, 3);
  }
}

.c-h3-mb {
  margin-bottom: 3px;
}

.c-h4-mb {
  @include view-at(pc) {
    margin-bottom: nth($space, 4);
  }

  @include view-at(tab) {
    margin-bottom: nth($space-tab, 4);
  }

  @include view-at(sp) {
    margin-bottom: nth($space-sp, 4);
  }
}

.c-h5-mb {
  @include view-at(pc) {
    margin-bottom: nth($space, 4);
  }

  @include view-at(tab) {
    margin-bottom: nth($space-tab, 4);
  }

  @include view-at(sp) {
    margin-bottom: nth($space-sp, 4);
  }
}
