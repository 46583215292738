
/* --------------------------
color
-------------------------- */
$color-red: #8b0000;
$color-red-dark: #8b0000;
$color-red-light: #8b0000;
$color-blue: #47a2d9;
$color-blue-dark: #47a2d9;
$color-blue-light: #47a2d9;
$color-white: #fff;
$color-black: #484848;

$color-gray: #999;
$color-gray-light: #ccc;
$color-gray-dark: #696969;
$color-gray-snow: #f5f5f5;


/* --------------------------
spacing
-------------------------- */
$space: 100px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 50px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 24px, 20px, 18px, 16px, 14px, 12px;
$line-height: 44px, 26px, 24px, 22px, 22px, 18px;

$font-size-sp: 24px, 20px, 18px, 16px, 14px, 12px;
$line-height-sp: 44px, 26px, 22px, 20px, 22px, 18px;

$font-size-h: 38px, 24px, 24px, 20px, 14px;
$line-height-h: 42px, 44px, 32px, 24px, 18px;

$font-size-h-sp: 32px, 24px, 20px, 18px, 14px;
$line-height-h-sp: 38px, 30px, 26px, 22px, 18px;


/* --------------------------
contents width
-------------------------- */
$contents-width: 1200px;
$contents-narrow-width: 760px;
$contents-padding: 20px;
$contents-padding-sp: 40px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  header,
  footer,
  modal
);
