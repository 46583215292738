
.p-instagram {
  &-list {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 12.5%;
      padding: 1px;

      @include view-at(sp) {
        width: 33.3333%;
        &:last-child {
            display: none;
        }
      }

      a {
        display: block;
        height: calc((100vw - 40px) / 8);
        @include view-at(sp) {
          height: calc(100vw / 3);
        }
        background-position: center;
        background-size: cover;
      }
    }
  }

  &-link {
    color: $color-red;
    text-decoration: none;
  }
}
